import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { Message } from "~/types";

export const useGPT = ({
  adType,
  messages,
  setStreamingMessage,
}: {
  adType: string;
  messages: Message[];
  setStreamingMessage: (message: string) => void;
}) => {
  return useMutation({
    mutationFn: async ({
      message,
      history,
      ad_type,
    }: {
      message: string;
      history: Message[];
      ad_type: string;
    }) => {
      const eventSource = new EventSource(
        `/chat?message=${encodeURIComponent(message)}&ad_type=${encodeURIComponent(
          adType.toLowerCase().replace(" ", "_"),
        )}&reset=${messages.length === 1}`,
      );

      let accumulatedMessage = "";

      return new Promise((resolve, reject) => {
        eventSource.addEventListener("message", (event) => {
          const data = JSON.parse(event.data);
          if (data.type === "all") {
            accumulatedMessage = data.content;
            setStreamingMessage(accumulatedMessage);
          } else {
            accumulatedMessage += data.content;
            setStreamingMessage(accumulatedMessage);
          }
        });

        eventSource.addEventListener("error", (event) => {
          if (event.eventPhase === EventSource.CLOSED) {
            eventSource.close();
            resolve(accumulatedMessage);
          } else {
            console.error("STREAM ERROR: ", event);
            eventSource.close();
            reject(new Error("EventSource failed"));
          }
        });
      });
    },
    onError: (error) => {
      console.log({ error });
      toast.error("Failed to send message. Please try again.");
    },
  });
};
