import React, { useEffect, useState } from "react";
import InputBox from "./InputBox";
import { BubbleType } from "./RootBubbleView";
import { UserBubble } from "./UserBubble";
import ChatAdModeSelector from "./ChatAdModeSelector";
import { ChatEmptyView } from "./ChatEmptyView";
import { useChatMode } from "~/providers/ChatModeProvider";
import AssistantBubble from "./AssistantBubble";
import { FollowUps } from "./FollowUps";
import { Message } from "~/types";
import { useGPT } from "~/hooks/useGPT";
import { Bug, User } from "lucide-react";
import { TelemetryView } from "./Telemetry";
import { useFollowUps } from "~/hooks/useFollowUps";
import { UserAttributes } from "./UserAttributes";

export const ChatContent = () => {
  const [isDebugPanelOpen, setIsDebugPanelOpen] = useState(false);
  const [isUserAttributesOpen, setIsUserAttributesOpen] = useState(false);
  const { adType, isFollowUpsEnabled } = useChatMode();
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<Message[]>([]);
  const userMessages = messages.filter((message) => message.role === "user");

  const [streamingMessage, setStreamingMessage] = useState<string>("");
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [followUps, setFollowUps] = useState<string[]>([]);
  const [adText, setAdText] = useState<string>();

  const { fetchFollowUps, isLoading, error } = useFollowUps();
  const { mutate, isPending: isGeneratingResponse } = useGPT({
    adType: adType,
    messages,
    setStreamingMessage,
  });
  const [isGenerating, setIsGenerating] = useState<boolean>(false);

  function didAskQuestion(question: string) {
    setFollowUps([]);
    ask(question);
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    ask(message);
  }

  function ask(message: string) {
    setIsGenerating(true);
    prepareForStream();

    // Add the user message to the messages array
    setMessages((prev) => [
      ...prev,
      { id: Math.random().toString(), role: "user", content: message },
    ]);

    mutate(
      { message, history: messages, ad_type: adType },
      {
        onSuccess: async (response: string) => {
          try {
            const followUps = await fetchFollowUps({
              question: message,
              answer: streamingMessage,
            });
            setFollowUps(followUps);

            const promises = [
              window.koah?.process(message, streamingMessage, adType.toLowerCase()),
            ];

            if (isFollowUpsEnabled) {
              promises.push(
                window.koah?.followUp(message, streamingMessage, followUps).then((data) => {
                  const sponsoredFollowUps = data.follow_ups;
                  setFollowUps([...followUps, ...sponsoredFollowUps]);
                }),
              );
            }

            const results = await Promise.all(promises);
            const koahResponse = results[0];
            if (koahResponse && koahResponse.ad_text) {
              setAdText(koahResponse.ad_text);
            }

            setIsGenerating(false);
          } catch (error) {
            console.error("Error processing message: ", error);
            setIsGenerating(false);
          }
        },
        onSettled: (response: string, error) => {
          console.log("RESPONSE: ", response);
          if (response) {
            setStreamingMessage("");
            // Add the assistant message to the messages array
            setMessages((prev) => [
              ...prev,
              {
                id: Math.random().toString(),
                role: "assistant",
                content: response,
              },
            ]);
          }
          setFollowUps([]);
        },
      },
    );
  }

  function prepareForStream() {
    setMessage("");
    setFollowUps([]);
  }

  useEffect(() => {
    function handleEscape(e: KeyboardEvent) {
      if (e.key === "Escape") {
        setIsDebugPanelOpen(false);
      }
    }

    document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  }, []);

  return (
    <>
      <div className="absolute top-4 left-0 px-4 flex justify-between w-full z-10">
        <ChatAdModeSelector />
        <div className="flex">
          <button
            className={`rounded-lg p-2 hover:bg-gray-100 w-10 h-10 flex items-center justify-center transition-all duration-300 text-black ${
              isUserAttributesOpen
                ? "bg-gray-100 hover:text-black"
                : "bg-white hover:text-slate-500"
            }`}
            onClick={() => {
              setIsUserAttributesOpen(!isUserAttributesOpen);
              if (isDebugPanelOpen) setIsDebugPanelOpen(false);
            }}
          >
            <User size={20} />
          </button>
          <button
            className={`rounded-lg p-2 hover:bg-gray-100 w-10 h-10 flex items-center justify-center transition-all duration-300 text-black ${
              isDebugPanelOpen ? "bg-gray-100 hover:text-black" : "bg-white hover:text-slate-500"
            }`}
            onClick={() => {
              setIsDebugPanelOpen(!isDebugPanelOpen);
              if (isUserAttributesOpen) setIsUserAttributesOpen(false);
            }}
          >
            <Bug size={20} />
          </button>
        </div>
      </div>

      <div className="flex w-full h-screen">
        {/* Main Container */}
        <div
          className={`flex-1 transition-all duration-300 transform ${
            isDebugPanelOpen ? "" : "translate-x-0"
          }`}
        >
          {/* Chat Content */}
          <div className="flex flex-col items-center justify-center h-full relative">
            {messages.length === 0 && <ChatEmptyView />}
            <div className="flex flex-col w-full h-full gap-4 overflow-y-scroll pb-[300px]">
              <div className="flex flex-col gap-4 min-w-[700px] max-w-[700px] mx-auto">
                {messages.map((message, idx) => {
                  const bubbleType =
                    idx === 0
                      ? BubbleType.FIRST
                      : idx === messages.length - 1 && !streamingMessage
                      ? BubbleType.LAST
                      : null;

                  if (message.role === "assistant") {
                    return (
                      <AssistantBubble
                        key={message.id}
                        type={bubbleType}
                        text={message.content}
                        isGeneratingResponse={false}
                      />
                    );
                  } else {
                    return <UserBubble key={message.id} type={bubbleType} message={message} />;
                  }
                })}
                {streamingMessage && (
                  <AssistantBubble
                    key={messages.length + 1}
                    text={streamingMessage}
                    type={BubbleType.LAST}
                    isGeneratingResponse={isGeneratingResponse}
                  />
                )}
                {isFollowUpsEnabled && followUps.length > 0 && (
                  <FollowUps
                    followUps={followUps}
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                    didAskQuestion={didAskQuestion}
                  />
                )}
              </div>
            </div>

            <div className="absolute bottom-0 left-0 right-0 bg-white">
              <InputBox
                message={message}
                setMessage={setMessage}
                onSubmit={handleSubmit}
                isGenerating={isGenerating}
              />
            </div>
          </div>
        </div>

        {/* User Attributes Panel */}
        <div
          className={`transition-all duration-300 border-l border-gray-200 bg-white overflow-hidden ${
            isUserAttributesOpen ? "w-[40%]" : "w-0"
          }`}
        >
          {isUserAttributesOpen && (
            <div className="h-screen overflow-y-auto p-4">
              <UserAttributes adText={adText} />
            </div>
          )}
        </div>

        {/* Debug Panel */}
        <div
          className={`transition-all duration-300 border-l border-gray-200 bg-white overflow-hidden ${
            isDebugPanelOpen ? "w-[40%]" : "w-0"
          }`}
        >
          {isDebugPanelOpen && (
            <div className="h-screen overflow-y-auto p-4">
              <TelemetryView
                isGenerating={isGenerating}
                answer={
                  userMessages.length > 0 ? userMessages[userMessages.length - 1].content : ""
                }
                onClose={() => setIsDebugPanelOpen(false)}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
