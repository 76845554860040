import React, { useState } from "react";
import { Card, CardHeader, CardTitle, CardContent } from "~/shadcn/components/ui/card";
import { FileText, Tag } from "lucide-react";
import { useMutation } from "@tanstack/react-query";
import { Button } from "~/shadcn/components/ui/button";

const ATTRIBUTES = [
  { label: "Budget", value: 50 },
  { label: "Durability", value: 91 },
  { label: "Overpronation", value: 72 },
  { label: "Purchase intent", value: 84 },
  { label: "Arch height", value: 18 },
];

const Pentagon = () => {
  return (
    <div className="w-[300px] h-[300px] relative flex mx-auto">
      <div className="absolute inset-0 flex items-center justify-center">
        {ATTRIBUTES.map((attr, i) => {
          const angle = (i * 2 * Math.PI) / 5 - Math.PI / 2;
          const radius = 120;
          const labelRadius = radius + 20;
          const x = 150 + labelRadius * Math.cos(angle);
          const y = 150 + labelRadius * Math.sin(angle);
          const valueRadius = (radius * attr.value) / 100;
          const valueX = 150 + valueRadius * Math.cos(angle);
          const valueY = 150 + valueRadius * Math.sin(angle);

          const nextIndex = (i + 1) % ATTRIBUTES.length;
          const nextAngle = (nextIndex * 2 * Math.PI) / 5 - Math.PI / 2;
          const nextValueRadius = (radius * ATTRIBUTES[nextIndex].value) / 100;
          const nextValueX = 150 + nextValueRadius * Math.cos(nextAngle);
          const nextValueY = 150 + nextValueRadius * Math.sin(nextAngle);

          return (
            <React.Fragment key={attr.label}>
              <div
                className="absolute transform -translate-x-1/2 -translate-y-1/2 text-sm font-medium"
                style={{
                  left: `${x}px`,
                  top: `${y}px`,
                }}
              >
                <div className="text-center">
                  <div>{attr.label}</div>
                  <div className="text-gray-600">{attr.value}%</div>
                </div>
              </div>
              <svg className="absolute w-[300px] h-[300px] pointer-events-none">
                {/* Background pentagon lines */}
                <line
                  x1="150"
                  y1="150"
                  x2={150 + radius * Math.cos(angle)}
                  y2={150 + radius * Math.sin(angle)}
                  className="stroke-gray-200"
                  strokeWidth="1"
                />
                {i < ATTRIBUTES.length - 1 && (
                  <line
                    x1={150 + radius * Math.cos(angle)}
                    y1={150 + radius * Math.sin(angle)}
                    x2={150 + radius * Math.cos(((i + 1) * 2 * Math.PI) / 5 - Math.PI / 2)}
                    y2={150 + radius * Math.sin(((i + 1) * 2 * Math.PI) / 5 - Math.PI / 2)}
                    className="stroke-gray-200"
                    strokeWidth="1"
                  />
                )}
                {i === ATTRIBUTES.length - 1 && (
                  <line
                    x1={150 + radius * Math.cos(angle)}
                    y1={150 + radius * Math.sin(angle)}
                    x2={150 + radius * Math.cos(-Math.PI / 2)}
                    y2={150 + radius * Math.sin(-Math.PI / 2)}
                    className="stroke-gray-200"
                    strokeWidth="1"
                  />
                )}

                {/* Fill polygon between points */}
                {i === 0 && (
                  <polygon
                    points={ATTRIBUTES.map((_, idx) => {
                      const a = (idx * 2 * Math.PI) / 5 - Math.PI / 2;
                      const r = (radius * ATTRIBUTES[idx].value) / 100;
                      return `${150 + r * Math.cos(a)},${150 + r * Math.sin(a)}`;
                    }).join(" ")}
                    className="fill-black/10"
                  />
                )}

                {/* Value lines */}
                <line
                  x1="150"
                  y1="150"
                  x2={valueX}
                  y2={valueY}
                  className="stroke-black"
                  strokeWidth="2"
                />
                <line
                  x1={valueX}
                  y1={valueY}
                  x2={nextValueX}
                  y2={nextValueY}
                  className="stroke-black"
                  strokeWidth="2"
                />
              </svg>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export const UserAttributes = ({ adText }: { adText: string }) => {
  const [updatedText, setUpdatedText] = useState<string>("");

  const { mutate: updateAdText, isPending } = useMutation({
    mutationFn: async () => {
      const response = await fetch("/update_ad_text", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ad_text: adText,
          attributes: ATTRIBUTES,
        }),
      });
      return response.json();
    },
    onSuccess: (data) => {
      setUpdatedText(data.updated_text);
    },
  });

  return (
    <div className="flex items-center justify-center h-full">
      <div className="w-full min-w-[350px] max-w-2xl space-y-4">
        <Card className="mx-auto">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">User Attributes</CardTitle>
            <Tag className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <Pentagon />
          </CardContent>
        </Card>

        {adText && (
          <Card className="mx-auto">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Ad Text Optimization</CardTitle>
              <FileText className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent className="flex flex-col gap-4">
              <p className="text-sm text-gray-600">
                The attributes shown in the pentagon above will be used to optimize your ad text.
                The system will analyze the current text and adjust it to better align with the
                selected user characteristics.
              </p>

              <div className="p-4 bg-gray-50 rounded-lg">
                <h3 className="text-sm font-medium mb-2">Current Ad Text:</h3>
                <p className="text-sm text-gray-600">{adText}</p>
              </div>

              <Button onClick={() => updateAdText()} disabled={isPending} className="w-full">
                {isPending ? "Updating..." : "Update Ad Text with Attributes"}
              </Button>

              {updatedText && (
                <div className="p-4 bg-gray-50 rounded-lg">
                  <h3 className="text-sm font-medium mb-2">Updated Ad Text:</h3>
                  <p className="text-sm text-gray-600">{updatedText}</p>
                </div>
              )}
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
};
